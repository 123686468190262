export interface ExternalKeyApplication {
  _id: string
  isDefault: boolean
  description: string
  company: string
  category: string
}

export const useExternalKeyApplicationStore = defineStore("external-key-applications", () => {
  /** @deprecated */
  const externalKeyApplications = ref<Array<ExternalKeyApplication>>([])

  const getExternalKeyApplications = async (queryParameters?: Record<string, any>): Promise<Array<ExternalKeyApplication>> => {
    return useCrudMethods("/external-key-applications", externalKeyApplications).readItems({ pagination: false, ...queryParameters })
  }

  const getExternalKeyApplicationById = async (id: string): Promise<ExternalKeyApplication> => {
    return useCrudMethods("/external-key-applications", externalKeyApplications).readItemById(id)
  }

  return {
    externalKeyApplications,

    getExternalKeyApplications,
    getExternalKeyApplicationById,
  }
})
